import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Header from "../../Header";
import piologo from "../../assets/image/pio-logo.png";
import RecentBlogSection from "./RecentBlogSection";
import blogbig from "../../assets/image/blog-big.png"
import Footer from "../../Footer";
import blogmono from "../../assets/image/Blog/monocarton-big.png"


class MonocartonBlog extends React.Component {
    render() {
        return (
            <>
                <div>
                    <Header></Header>
                    <section className="page-banner">
                        <div className="container-fluid px-md-4 px-3">
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <div className="top-breadcrumb">
                                        <div className="w-100 text-center inner-pio-phn-logo justify-content-center d-md-none d-flex">
                                            <img src={piologo} alt="pio-packaging" />
                                        </div>

                                        <ul>
                                            <li className="breadcrumb-item">
                                                <a href="http://piopackaging.com">Home</a>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <a href="http://piopackaging.com#products">Blogs</a>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page">
                                                What is Mono Carton?
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                    <section className="blog-detail-main pt-5 mt-lg-5 pb-lg-5">
                        <div className="container-fluid px-lg-5">
                            <div className="row">
                                <div className="col-lg-8 mb-4">

                                    <img
                                        src={blogmono}
                                        className="img-fluid"
                                        alt="                                                         Mono Carton


"
                                    />
                                    <div className="blog-main-content">

                                        <div className="blog-btn-card p-0 mt-4 d-flex">
                                            <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>
                                            <span >03-01-2025</span>
                                        </div>

                                        <h1 className="main-title  main-title-3 wow animate__animated animate__fadeInUp mb-2 mt-3">
                                            What is Mono Carton?

                                        </h1>
                                        <p className="main-text text-start wow animate__animated animate__fadeInUp">
                                            A mono carton is a single-layer box made from a sheet of cardboard or paperboard that's printed, die cut to a shape, folded and glued together. They are also known as folding cartons. <a href="/mono-cartons" className=" fw-bold">Mono cartons</a> are a common packaging choice for a variety of industries, including retail, food, pharmaceutical, healthcare, lifestyle, clothes, toys, confectionery, baked goods, electrical & electronics, Industrial and a host of endless products.                                    </p>


                                        <h5 class=" wow animate__animated animate__fadeInUp my-3">The reason mono cartons are most preferred for packaging a product is because they are :-</h5>
                                        <p className="main-text text-start wow animate__animated animate__fadeInUp">
                                            <span>1.</span> Versatile - They can be used for a wide range of products.
                                        </p>


                                        <p className="main-text text-start wow animate__animated animate__fadeInUp">
                                            <span>2.</span> Attractive - These Boxes can be printed with captivating designs, images and colours which is an important aspect of packaging.                                             </p>
                                        <p className="main-text text-start wow animate__animated animate__fadeInUp">
                                            <span>3.</span> Cost-effective - They can be produced at low costs with ease of production.                    </p>


                                        <p className="main-text text-start wow animate__animated animate__fadeInUp">
                                            <span>4.</span> Easy to customize - Mono Carton Boxes can be printed with logos and information, and can be made in a variety of sizes and shapes.
                                        </p>


                                        <p className="main-text text-start wow animate__animated animate__fadeInUp">
                                            <span>5.</span> Eco-friendly - It is generally made using paper boards which are biodegradable.                                     </p>


                                        <p className="main-text text-start wow animate__animated animate__fadeInUp">
                                            <span>6.</span> Storage - Since mono cartons are designed to be supplied folded flat it consumes a fraction of the space compared to his final box shape.                 </p>



                                        <p className="main-text text-start wow animate__animated animate__fadeInUp">
                                            <span>7.</span> Easy to assemble - It is very easy to assemble and use and can be formed from the supplied flat shape to box in less than 5 to 10 seconds.                  </p>



                                        <p className="main-text text-start wow animate__animated animate__fadeInUp">
                                            <span>8.</span> Durable - Mono cartons can be made using sturdy cardboard and a design that can withstand physical impact and can protect the product in encompasses.
                                        </p>


                                        <p className="main-text text-start wow animate__animated animate__fadeInUp">
                                            <span>9.</span> Shipping - Mono cartons are supplied flat so it consumes the lowest volume in space and shipping costs are greatly reduced.





                                        </p>


                                        <p className="main-text text-start wow animate__animated animate__fadeInUp">
                                            <span>10.</span>                                             Recyclable - These cartons being made of paper boards, are 100% recyclable.
                                        </p>






                                    </div>
                                </div>



                                <div className="col-lg-4">
                                    <RecentBlogSection />




                                </div>

                            </div>
                        </div>
                    </section>
                </div>
                <Footer></Footer>

            </>
        )

    }
}

export default MonocartonBlog;
