import React, { Component } from "react";
import Footer from "../../Footer";
import Header from "../../Header";
import PostApiCall from "../../components/helper/Api";
import Notiflix from "notiflix";
import moment from "moment";

class OrderSample extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Viewlargeimage:
        "https://www.piopackaging.com/assets/img/industries/cosmetics-health-beauty.jpg",
      BoxName: this.props.match.params.boxname,
      BoxSizeData: [],
      termscondition: "No",
      VariantId: null,
      OrderDiscription: "",
      VerticalName: "",
      VerticalImage: "",
    };
  }

  componentDidMount() {
    Notiflix.Notify.init({
      width: "300px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
    });

    localStorage.removeItem("Ordertype");

    localStorage.removeItem("OrderData");

    PostApiCall.postRequest(
      {
        recordCount: "*",
        whereClause: `where fld_status = 'Active' and fld_variantid=${parseInt(
          this.props.match.params.verticalid
        )}`,
      },
      "GetVariantData"
    ).then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          BoxSizeData: obj.data,
          VariantId: this.props.match.params.verticalid,
          VerticalName: obj.data[0].fld_itemname,
          VerticalImage: obj.data[0].VariantImage.split("#")[0],
        });
      })
    );
  }

  onOrderNow = () => {
    Notiflix.Loading.circle();
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    this.setState(
      {
        OrderDiscription:
          '<thead class="orderheader"><tr>' +
          '<th class="ordersummarylist2" colSpan="2">Item Description</th>' +
          '<th class="ordersummarylist">Quantity</th>' +
          '<th class="ordersummarylist">Unit Price</th>' +
          '<th class="ordersummarylist">Shipping</th>' +
          '<th class="ordersummarylist">GST</th>' +
          '<th class="ordersummarylist">Total Amount</th>' +
          "</tr></thead>" +
          '<tbody><tr><td class="item-summary-image" style="width: 9%;">' +
          '<img style="width: 65%" src=' +
          this.state.VerticalImage +
          "></img>" +
          "</td>" +
          '<td style="border-left: hidden">' +
          '<p style="text-align:justify">' +
          this.state.VerticalName +
          "</p>" +
          '<table class="innertable">' +
          "<tr>" +
          "<td>Box</td><td>: " +
          this.state.BoxName +
          "</td></tr>" +
          "<tr><td>Outer Coating</td><td>: No Coating </td></tr><tr>" +
          "<td>Inner Coating</td><td>: No Coating </td>" +
          "</tr>" +
          "</table>" +
          "</td>" +
          '<td class="ordersummarylist" style="text-align:center"> 1 </td>' +
          // '<td class="ordersummarylist" style="text-align: right">₹ 424.00</td>',
          // '<td class="ordersummarylist" style="text-align: right">₹ 446.43</td>',
          '<td class="ordersummarylist" style="text-align: right">₹ 892.85</td>',
        // '<td class="ordersummarylist" style="text-align: right">₹' + this.state.CustomQuantity_Deliverycharge.toLocaleString(undefined, { maximumFractionDigits: 2 }) + '</td>'
      },
      () => {
        PostApiCall.postRequest(
          {
            orderdate: moment().format("lll"),
            itemdetails: `
                                          [
                                          { 
                                              "Item":${this.state.VariantId},
                                              "Quantity":${1}
                                          }
                                          ]
                                          `,
            orderdescription: this.state.OrderDiscription,
            shippingcharge: 0,
            status: "INCART",
            customerid:
              localStorage.getItem("CustomerData") == null
                ? null
                : logindetails[0].fld_customerid,
            createdon: moment().format("lll"),
            updatedon: moment().format("lll"),
            orderid: 0,
            // gst: 76.32,
            // gst: 53.57,
            gst: 107.15,
            // totalamount: 500,
            totalamount: 1000,
            updatedby:
              localStorage.getItem("CustomerData") == null
                ? null
                : logindetails[0].fld_customerid,
            outercoatingid: 0,
            innercoatingid: 0,
            boardid: 0,
            deliverytype: "StandardShipping",
          },
          "AddToCartWebsite"
        ).then((result) =>
          result.json().then((obj) => {
            if (result.status == 200 || result.status == 201) {
              Notiflix.Loading.remove();
              // Notiflix.Notify.success('Product added to Cart.')

              if (localStorage.getItem("CustomerData") != null) {
                if (localStorage.getItem("OrderData") == null) {
                  localStorage.setItem(
                    "Ordertype",
                    JSON.stringify("Ordersample")
                  );

                  localStorage.setItem("OrderData", JSON.stringify(obj.data));
                  window.location.href = "/selectaddress";
                } else {
                  localStorage.setItem(
                    "Ordertype",
                    JSON.stringify("Ordersample")
                  );

                  localStorage.setItem("OrderData", JSON.stringify(obj.data));

                  window.location.href = "/selectaddress";
                }
              } else {
                localStorage.setItem(
                  "Ordertype",
                  JSON.stringify("Ordersample")
                );

                localStorage.setItem("OrderData", JSON.stringify(obj.data));
                window.location.href = "/login";
              }
            } else {
              Notiflix.Loading.remove();
              Notiflix.Notify.failure(obj.data);
            }
          })
        );
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <Header />

        {/* <section class="page-banner">
                    <div class="container">
                        <div class="row mt-4">
                            <div class="col-md-12">
                                <div class="top-breadcrumb">
                                    <ul>
                                        <li class="breadcrumb-item"><a href="http://piopackaging.com">Home</a></li>
                                        <li class="breadcrumb-item"><a href="http://piopackaging.com#products">Products</a></li>
                                        <li class="breadcrumb-item"><a href="http://piopackaging.com#products">Customize your Box</a></li>
                                        <li class="breadcrumb-item active" aria-current="page">Order Sample</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

        <section style={{ marginBottom: "20px", paddingTop: "40px" }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5">
                <div className="section-title rightcarttoptext rightCardOuterorder">
                  {this.state.BoxSizeData.map((data) => (
                    <h3 className="leftcardText" style={{ fontSize: "20px" }}>
                      {data.fld_itemname}: {data.fld_variantname}{" "}
                    </h3>
                  ))}
                </div>
                <div className="leftCard">
                  <div className="largeImage order-img-large-phn">
                    {this.state.BoxSizeData.map((data) => (
                      <img
                        src={data.VariantImage}
                        alt=""
                        style={{ width: "95%" }}
                      ></img>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-md-7 rightcardbg">
                <div className="rightCardOuterorder-right rightcardshadow">
                  <div className="jss5">
                    <h4>Order Sample Box</h4>
                  </div>

                  <div className="section-title rightcarttoptext">
                    <h4 className="rightcardheading mb-2">
                      {/* <b>₹ 500/-</b> */}
                      <b>
                        ₹ 1000/-{" "}
                        <spna className="text-danger fw-800">
                          ( FULLY REFUNDABLE against your bulk order for this size and item )
                        </spna>
                      </b>
                    </h4>
                    <p className="rightcardparag">
                      Inclusive of GST and Shipping Charges.
                    </p>
                  </div>
                  <div className="rightCard">
                    <h4>What does your sample box kit contain?</h4>
                    <hr />
                    <ul className="orderSampleList">
                      <li>
                        An exact sample of the of box you wish to order in two
                        qualities of boards – White Backed Duplex Board &amp;
                        Folding Box Board - Digital Printed without any coating.
                      </li>
                      <br />
                      <li>
                        Sample cards having the following outer coating finishes
                        <ul className="orderSampleList2">
                          <li>
                            No coating on generic design Offset Printed boards
                          </li>
                          <li>
                            Gloss Aqueous coating on generic design Offset
                            Printed boards
                          </li>
                          <li>
                            Gloss Lamination on generic design Offset Printed
                            boards
                          </li>
                          <li>
                            Matt Lamination on Generic design Offset Printed
                            boards
                          </li>
                        </ul>
                      </li>
                      <br />
                      <li>
                        Samples of White Backed Duplex Board &amp; Folding Box
                        Board showing Inner food grade ‘Liquid / Oil barrier’
                        Coating ( recommended for greasy food products)
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="col-md-12 px-1"
                  style={{ marginBottom: "20px" }}
                >
                  <div className="gridCard strength2">
                    <div className="gridcardcheckbox-order">
                      <div className="d-flex mb-lg-0 mb-3">
                        <input
                          type="checkbox"
                          id="vehicle1"
                          name="vehicle1"
                          value={this.state.termscondition}
                          checked={
                            this.state.termscondition == "Yes" ? true : false
                          }
                          onChange={() => {
                            if (this.state.termscondition == "Yes") {
                              this.setState({
                                termscondition: "No",
                              });
                            } else {
                              this.setState({
                                termscondition: "Yes",
                              });
                            }
                          }}
                        />
                        <p for="vehicle1" className="mb-0">
                          {" "}
                          &nbsp;I agree to the{" "}
                          <a href="/termsandcondition">Terms & Conditions</a>
                        </p>
                      </div>
                      <div className="buttons-div">
                        <button
                          className="btn designbtn2 active"
                          onClick={() => {
                            if (this.state.termscondition == "Yes") {
                              this.onOrderNow();
                            } else {
                              Notiflix.Notify.failure(
                                "Please select terms and condition"
                              );
                            }
                          }}
                        >
                          Order Now
                        </button>
                        <button
                          className="btn designbtn2 active"
                          onClick={() => {
                            this.props.history.goBack();
                          }}
                        >
                          Go back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer style={{ marginTop: "20px" }} />
      </React.Fragment>
    );
  }
}

export default OrderSample;
