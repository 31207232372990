import React, { Component } from "react";

import "./App.css";
import Notiflix from "notiflix";
// import GetApiCall from './GetApi';
import PostApiCall from "./components/helper/Api";
import Footer from "./Footer";
import Header from "./Header";
import Products from "./Products";
import EnquiryImg from "./enquiry.jpeg";
import piologo from "../src/assets/image/pio-logo.png";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Name: "",
      CompanyName: "",
      Mobile: "",
      Email: "",
      Pincode: "",
      Message: "",
      DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
      NumRegex: /^[0-9]*$/,
      PincodeRegex: /^[0-9]*$/,
      AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
      EmailRegex:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    };
  }

  onChangeName(name) {
    this.setState({
      Name: name.target.value,
    });
  }
  onChangeCompany(company) {
    this.setState({
      CompanyName: company.target.value,
    });
  }
  onChangeMessage(message) {
    this.setState({
      Message: message.target.value,
    });
  }
  onChangeMobile(mobile) {
    if (
      this.state.NumRegex.test(mobile.target.value) &&
      mobile.target.value.length <= 10
    ) {
      this.setState({
        Mobile: mobile.target.value,
      });
    }
  }
  onChangeEmail(email) {
    this.setState({
      Email: email.target.value,
    });
  }
  onChangePincode(pincode) {
    if (
      this.state.PincodeRegex.test(pincode.target.value) &&
      pincode.target.value.length <= 6
    ) {
      this.setState({
        Pincode: pincode.target.value,
      });
    }
  }

  onPost = () => {
    Notiflix.Loading.dots("Please wait...");

    // info @pioprinters.com
    PostApiCall.postRequest(
      {
        admin_email: "enquirypiopackaging@gmail.com",
        email_from: "piopackaging@gmail.com",
        email_to: "info@piopackaging.com",
        admin_name: "PioPackaging.com",
        admin_password: "piopackaging1234",
        admin_port: "587",
        admin_logo: "https://piopackaging.com/assets/img/PioLogo.png",
        admin_host: "smtp.gmail.com",
        admin_url: "https://piopackaging.com/",
        mail_title:
          "Pio Packaging - Best quality custom boxes and packaging supplies.",
        customer_email: this.state.Email,
        customer_name: this.state.Name,
        customer_mobile: this.state.Mobile,
        customer_message: this.state.Message,
        customer_company: this.state.CompanyName,
        customer_pincode: this.state.Pincode,
      },
      "enquiryMailer"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success(
            "Thanks for Writing to us..! Our Team will Contact You Soon..."
          );
          window.location.href = "https://piopackaging.com/";
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(obj.data);
        }
      })
    );
  };

  SaveEnquiry() {
    if (this.state.Name != "") {
      if (this.state.Mobile != "") {
        if (this.state.Mobile.length == 10) {
          if (this.state.Email != "") {
            if (this.state.EmailRegex.test(this.state.Email)) {
              if (this.state.Pincode != "") {
                if (this.state.Pincode.length == 6) {
                  this.onPost();
                } else {
                  Notiflix.Notify.failure("Please enter valid pincode.");
                }
              } else {
                Notiflix.Notify.failure("Please enter area pincode.");
              }
            } else {
              Notiflix.Notify.failure("Please enter valid email address.");
            }
          } else {
            Notiflix.Notify.failure("Please enter email address.");
          }
        } else {
          Notiflix.Notify.failure("Please enter valid mobile number.");
        }
      } else {
        Notiflix.Notify.failure("Please enter mobile number.");
      }
    } else {
      Notiflix.Notify.failure("Please enter your name.");
    }
  }

  render() {
    return (
      <div>
        <Header></Header>

        <section className="page-banner">
          <div className="container-fluid px-md-4 px-3">
            <div className="row mt-4">
              <div className="col-md-12">
                <div className="top-breadcrumb">
                  <div className="w-100 text-center inner-pio-phn-logo justify-content-center d-md-none d-flex">
                    <img src={piologo} alt="pio-packaging" />
                  </div>
                  <ul>
                    <li className="breadcrumb-item">
                      <a href="http://piopackaging.com/">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Enquiry
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container-fluid px-md-4 px-3">
            <div className="row">
              <div className="col-md-12 pb-0 mb-0">
                <div className="section-title text-center mb-0 pb-0">
                  <h1 className="mb-2 comon-h1-enquiry-font">WRITE TO US</h1>
                  {/* <h2 className="mb-2">WRITE TO US</h2> */}
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-12">
                <div className="inner-page-cont">
                  <p style={{ textAlign: "center" }}>
                    "We will be happy to respond to your enquiry. Fill out the
                    form and we will get in touch with you"
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-7">
                <div className="middle-box">
                  <div className="middle">
                    <div className="overview-txt">
                      <form
                        className="contact-form"
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <div className="row">
                          <div className="form-group col-12 col-md-6">
                            <input
                              className="form-control py-4 w-100"
                              placeholder="Name"
                              type="text"
                              name="name"
                              value={this.state.Name}
                              onChange={this.onChangeName.bind(this)}
                            />
                          </div>
                          <div className="form-group col-12 col-md-6">
                            <input
                              className="form-control py-4 w-100"
                              placeholder="Company Name"
                              type="text"
                              name="name"
                              value={this.state.CompanyName}
                              onChange={this.onChangeCompany.bind(this)}
                            />
                          </div>
                          <div className="form-group col-12 col-md-6">
                            <input
                              className="form-control py-4 w-100"
                              placeholder="Mobile"
                              type="text"
                              name="mobile"
                              value={this.state.Mobile}
                              onChange={this.onChangeMobile.bind(this)}
                            />
                          </div>
                          <div className="form-group col-12 col-md-6">
                            <input
                              className="form-control py-4 w-100"
                              placeholder="Email"
                              name="email"
                              value={this.state.Email}
                              onChange={this.onChangeEmail.bind(this)}
                            />
                          </div>
                          <div className="form-group col-12 col-md-6">
                            <input
                              className="form-control py-4 w-100"
                              placeholder="Area Pincode"
                              type="text"
                              name="pincode"
                              value={this.state.Pincode}
                              onChange={this.onChangePincode.bind(this)}
                            />
                          </div>
                          <div className="form-group col-12">
                            <textarea
                              name="message"
                              rows="5"
                              placeholder="Message"
                              className="form-control w-100"
                              value={this.state.Message}
                              onChange={this.onChangeMessage.bind(this)}
                            ></textarea>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-12 w-100 text-right">
                            <button
                              type="submit"
                              className="btn btn-cont-w3 w-100"
                              onClick={this.SaveEnquiry.bind(this)}
                            >
                              Send Enquiry{" "}
                              <i className="fa fa-paper-plane-o"></i>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="overview-img">
                  <img src={EnquiryImg} alt="Overview" />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <Products></Products> */}
        <Footer></Footer>
      </div>
    );
  }
}

export default App;
