import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import blogmono from "../../assets/image/Blog/monocarton-section.png"
import blogvarious from "../../assets/image/Blog/folding-box-section.png"
import blogbenefits from "../../assets/image/Blog/benifits-section.png"

class BlogSection extends React.Component {
  render() {
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 3,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };
    return (
      <div className="container-fluid px-md-4 px-3 mt-4 mb-2 home-blog-section">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title text-center mb-md-4 mb-3">
              <h2>Blogs</h2>
            </div>
          </div>
        </div>
        {/* **** Desktop view ********** */}
        <Carousel responsive={responsive} className="pb-4">
          <div className="industries-box mx-3">
            <div className="industries-box-img">
              <a href="/what-is-mono-carton">
                <img
                  className="img-fluid"
                  src={blogmono}
                  alt="mono cartons"
                />

              </a>
            </div>
            <div className="industries-box-txt blog-content">
              <div className="d-flex align-items-center">
                <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>
                <span >03-01-2025</span>
              </div>
              <h2>What is Mono Cartons?</h2>

              <p>
                A mono carton is a single-layer box made from a sheet of cardboard or paperboard that's printed, die cut to a shape, folded and glued together. They are also known as folding cartons. Mono cartons are a common packaging choice for a variety of industries, including retail, food, pharmaceutical, healthcare, lifestyle, clothes, toys, confectionery, baked goods, electrical & electronics, Industrial and a host of endless products.
              </p>
              <a href="/what-is-mono-carton" className="btn mt-3">Read More</a>
            </div>
          </div>



          <div className="industries-box mx-3">
            <div className="industries-box-img">
              <a href="/what-are-the-various-types-of-folding-boxes">
                <img
                  className="img-fluid border-bottom"
                  src={blogvarious}
                  alt="various types of Folding Boxes"
                />

              </a>
            </div>
            <div className="industries-box-txt blog-content">
              <div className="d-flex align-items-center">
                <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>
                <span >05-01-2025</span>
              </div>
              <h2>What are the Various Types of Folding Boxes?</h2>

              <p>
                These boxes feature flats that tuck into the box body, providing a secure closure without the need for tape or glue. They’re commonly used for retail products, such as small electronics, cosmetics, and food items. Tuck-end boxes are quick to assemble and are lightweight, making them a popular choice in the packaging industry.
              </p>
              <a href="/what-are-the-various-types-of-folding-boxes" className="btn mt-3">Read More</a>
            </div>
          </div>
          <div className="industries-box mx-3">
            <div className="industries-box-img">
              <a href="/benefits-of-folding-cartons-for-businesses-and-consumers">
                <img
                  className="img-fluid"
                  src={blogbenefits}
                  alt=" Folding cartons for businesses and consumers"
                />

              </a>
            </div>
            <div className="industries-box-txt blog-content">
              <div className="d-flex align-items-center">
                <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>
                <span >08-01-2025</span>
              </div>
              <h2>What are the Benefits of Folding Cartons for Businesses and Consumers </h2>

              <p>
                Folding boxes are a flexible, cost-effective, and environmentally friendly packaging solution that meets the needs of modern businesses and consumers. With their versatility, ease of storage, and potential for customization, folding boxes offer an ideal solution for packaging a wide variety of products. Whether you're a small business looking to reduce packaging costs or a large corporation seeking sustainable alternatives, folding boxes provide a practical and efficient option that supports both branding and eco-friendly initiatives.
              </p>
              <a href="/benefits-of-folding-cartons-for-businesses-and-consumers" className="btn mt-3">Read More</a>
            </div>
          </div>
          {/* <div className="industries-box mx-3">
            <div className="industries-box-img">
              <a href="">
                <img
                  className="img-fluid"
                  src={blogImg}
                  alt="Blog Title"
                />

              </a>
            </div>
            <div className="industries-box-txt blog-content">
              <div className="d-flex align-items-center">
                <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>
                <span >03-01-2025</span>
              </div>
              <h2>Why Attractive Packaging drives sale</h2>

              <p>
                Packaging plays a crucial role in a consumer's purchasing decision. It’s not just a container for a product; it’s a silent salesperson that communicates the brand's value and identity. Attractive packaging often acts as the first point of interaction between a product and its potential buyer, significantly influencing purchasing behavior. But why does appealing packaging drive sales? Let’s delve into the psychology and marketing dynamics behind it.
              </p>
              <a href="" className="btn mt-3">Read More</a>
            </div> 
          </div> */}
          {/* <div className="industries-box mx-3">
            <div className="industries-box-img">
              <a href="">
                <img
                  className="img-fluid"
                  src={blogImg}
                  alt="Blog Title"
                />

              </a>
            </div>
            <div className="industries-box-txt blog-content">
              <div className="d-flex align-items-center">
                <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>
                <span >03-01-2025</span>
              </div>
              <h2>Are Folding cartons Eco-friendly?</h2>

              <p>
                Folding cartons can be eco-friendly, but it depends on several factors, including the materials used, production processes, and end-of-life options. Here's a closer look:              </p>
              <a href="" className="btn mt-3">Read More</a>
            </div>
          </div>
          <div className="industries-box mx-3">
            <div className="industries-box-img">
              <a href="">
                <img
                  className="img-fluid"
                  src={blogImg}
                  alt="Blog Title"
                />

              </a>
            </div>
            <div className="industries-box-txt blog-content">
              <div className="d-flex align-items-center">
                <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>
                <span >03-01-2025</span>
              </div>
              <h2>Using creativity in Folding cartons to boost sales</h2>

              <p>
                Folding cartons offer a versatile canvas for creative packaging designs that can enhance brand identity and captivate consumers. Here are some innovative design ideas to consider: </p>
              <a href="" className="btn mt-3">Read More</a>
            </div>
          </div>
          <div className="industries-box mx-3">
            <div className="industries-box-img">
              <a href="">
                <img
                  className="img-fluid"
                  src={blogImg}
                  alt="Blog Title"
                />

              </a>
            </div>
            <div className="industries-box-txt blog-content">
              <div className="d-flex align-items-center">
                <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>
                <span >03-01-2025</span>
              </div>
              <h2>Difference between Folding carton box and Rigid box</h2>

              <p>
                When it comes to choosing the right packaging for your product, there are a few different basic options. You can go with a rigid, mono carton, or corrugated box. All of these have their unique benefits and drawbacks, so you'll need to decide which is best for your product. This post will look at the difference between a rigid box and a mono carton box.  </p>
              <a href="" className="btn mt-3">Read More</a>
            </div>
          </div> */}
        </Carousel>
      </div>
    );
  }
}

export default BlogSection;
